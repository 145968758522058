import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    cnyCourse: 10.86721111111
  },
  getters: {
    getUser: ({ user }) => () => user
  },

  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setCnyCourse(state, payload) {
      state.cnyCourse = payload
    }
  },

  actions: {
    setUser(context, payload) {
      context.commit('setUser', payload);
    },
    setCnyCourse(context, payload) {
      context.commit('setCnyCourse', payload);
    }
  }
});
