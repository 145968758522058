import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VueNotification from "@kugatsu/vuenotification";

Vue.use(VueNotification, {
  timer: 5,
  showLeftIcn: true,
  showCloseIcn: true,
  /* error: {
    background: "green",
    color: "red"
  } */
});

Vue.use(Vuetify);

export default new Vuetify({
  theme: { dark: true }
});
