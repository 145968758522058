<script>
export default {
  name: "NavBar",
  data() {
    return {
      drawer: !!this.$store.state.user.email
    };
  },
  watch: {
    user() {
      this.drawer = !!this.$store.state.user?.email;
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser();
    },
    items() {
      return [
        { title: this.$tr('orders'), icon: "mdi-cart-outline", to: "/orders" },
        ...(this.user.role == 'manager'
          ? [
              { title: "Юзеры", icon: "mdi-account-multiple-outline", to: "/users" },
              { title: "Шаблоны уведомлений", icon: "mdi-email-multiple-outline", to: "/mail-templates/1" }
            ] : []),
        { title: this.$tr('logout'), icon: "mdi-logout", to: "/login" },
      ]
    }
  }
}
</script>

<template>
  <nav>
    <v-app-bar app flat dense>
      <v-app-bar-nav-icon v-if="user.email" @click="drawer = !drawer" />

      <v-toolbar-items v-if="!user.email">
        <v-btn to="/login">Вход</v-btn>
        <v-btn to="/register-client">Регистрация</v-btn>
        <v-btn to="/login">Sign in</v-btn>
        <v-btn to="/register-seller">Seller Sign up </v-btn>
      </v-toolbar-items>

      <v-spacer />

      <v-toolbar-items>
        <v-btn @click="$vuetify.theme.dark = !$vuetify.theme.dark">
          <v-icon v-if="$vuetify.theme.dark">mdi-weather-sunny</v-icon>
          <v-icon v-else>mdi-weather-night</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Orders 1688
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ user.email }} <em>({{ user.role }})</em>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" link :to="item.to">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

    </v-navigation-drawer>
  </nav>
</template>
