<script>
import NavBar from './components/NavBar.vue';
import { axios, initAxios } from './api';
import _axs from 'axios';


export default {
  name: 'App',
  components: { NavBar },
  mounted() {
    initAxios(this.$router);
    axios.get('/user-auth/current').then(({ data }) => {
      this.$store.dispatch('setUser', data);
    });
    _axs.get('https://www.cbr-xml-daily.ru/daily_utf8.xml').then(({ data }) => {
      const cnyCourse = /CNY.+?<Value>(.+?)<\/Value>/.exec(data);
      this.$store.dispatch('setCnyCourse', +cnyCourse[1].replace(',', '.'));
    });
  }
};
</script>


<template>
  <v-app>
    <NavBar />
    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

