import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/orders'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ './pages/login-form')
  },
  {
    path: '/register-seller',
    name: 'register-seller',
    component: () => import(/* webpackChunkName: "register-seller" */ './pages/register-seller-form')
  },
  {
    path: '/register-client',
    name: 'register-client',
    component: () => import(/* webpackChunkName: "register-client" */ './pages/register-client-form')
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ './pages/users')
  },
  {
    path: '/user/:id',
    name: 'user',
    component: () => import(/* webpackChunkName: "user" */ './pages/user-form')
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import(/* webpackChunkName: "orders" */ './pages/orders/orders')
  },
  {
    path: '/order/:id',
    name: 'order',
    component: () => import(/* webpackChunkName: "order-form" */ './pages/orders/order-form')
  },
  {
    path: '/order-page/:id',
    name: 'orderPage',
    component: () => import(/* webpackChunkName: "order-page" */ './pages/orders/order-page')
  },
  {
    path: '/good-buyer-data/:orderGoodId',
    name: 'goodBuyerData',
    component: () => import(/* webpackChunkName: "order-page" */ './pages/orders/good-buyer-data')
  },
  {
    path: '/mail-templates/:index',
    name: 'mailTemplates',
    component: () => import(/* webpackChunkName: "mail-templates" */ './pages/mail-templates')
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router;
