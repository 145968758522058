import store from '@/store';

const translations = {
  order: ['Заказ', 'Order'],
  orders: ['Заказы', 'Orders'],
  client: ['Клиент','Customer'],
  goods: ['Товары', 'Goods'],
  qty: ['кол-во', 'quantity'],
  price: ['Цена', 'Price'],
  createdAt: ['Создан','Created at'],
  goodsQty: ['Кол-во товаров','Goods quantitiy'],
  totalPrice: ['Общая сумма', 'Total price'],
  rowsPerPage: ['Строк на страницу', 'Rows per page'],
  logout: ['Выход', 'Logout'],
  deliveryPrice: ['Стоимость доставки', 'Delivery Price'],
  link: ['Ссылка','Link'],
  description: ['Примечание','Description'],
  status: ['Статус', 'Status'],

  1: ['',''],
};

export default {
  install(Vue, options) {
    Vue.prototype.$tr = (key) => {
      const tr = translations[key];
      return !tr
        ? `_${key}_`
        : store.state.user.role == 'seller'
          ? tr[1]
          : tr[0];
    }
  }
}
