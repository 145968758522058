import qs from 'qs';
import _axios from 'axios';
let router;

const axios = _axios.create({ withCredentials: true });

axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error?.response?.status === 401) {
    router.push('/login');
  } else {
    throw error;
  }
});
axios.defaults.baseURL = process.env.NODE_ENV === 'development'
  ? 'http://localhost:3000/'
  : '/api';

const initAxios = (_router) => router = _router;

const api = {
  async loadUsers(tableOptions, filter) {
    const { page = 1, itemsPerPage = 10 } = tableOptions;
    const params = {
      fields: 'id,name,email,phone,city,country,role,createdAt',
      sort: 'id,DESC',
      limit: itemsPerPage,
      offset: (page - 1) * itemsPerPage
    }
    const { data: { data: users, total }} = await axios.get('/users', { params });
    return { users, total };
  },

  async loadOrders(tableOptions, filter) {
    const { page = 1, itemsPerPage = 10 } = tableOptions;
    const params = {
      sort: 'id,DESC',
      join: [
        'client||id,name,email',
        'orderGoods||name,price,quantity',
        'orderActions||image,name,createdAt'
      ],
      limit: itemsPerPage,
      offset: (page - 1) * itemsPerPage
    }
    const { data: { data: orders, total }} = await axios.get('/orders', { params });
    return { orders, total };
  }
};

export { axios, initAxios, api };
